import React, { Component } from "react"
import { navigate } from "gatsby"
import Form from "react-bootstrap/Form"
//import ReactDOM from "react-dom"

export default class Checkout extends React.Component {
  componentDidMount() {
    window.location.replace("https://carbondown.earth")
  }

  render() {
    return (
      <div />
    )
  }
}
